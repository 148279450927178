import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, timer } from 'rxjs';
import { catchError, delayWhen, retryWhen, scan, takeUntil } from 'rxjs/operators';
import { WebSocketSubject } from 'rxjs/webSocket';
import { RvshareAppDetectionService } from './rvshare-app-detection.service';
import { AnalyticsFactory } from 'src/app/ajs-upgraded-providers';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { WebSocketService } from 'src/app/shared/services/web-socket.service';
import { environment } from 'src/environments/environment';

export type ScreenShareMember = {
  memberId: string;
  appsMemberId?: string;
  mobileMemberId?: string;
  memberType: string;
  memberDisplayName?: string;
  sharing?: boolean;
};

export type SignalServerData = {
  type: string;
  id?: string;
  joinCode?: string;
  displayId?: string;
  iceConfiguration?: {
    iceServers: { urls: string }[]
  };
  candidate?: {
    candidate: string;
    sdpMLineIndex: number;
    sdpMid: string;
  };
  sdp?: string;
  channel?: any;
  memberId?: string;
  members?: ScreenShareMember[];
  senderId?: string;
  value?: number;
};

export const MessageType = {
  JOINED_CHANNEL: 'joined-channel',
  INVALID_CHANNEL: 'invalid-channel',
  ICECANDIDATE: 'icecandidate' as 'icecandidate',
  OFFER: 'offer',
  ANSWER: 'answer' as 'answer',
  NEW_MEMBER: 'new-member',
  MODERATOR_NOT_ALLOWED: 'moderator-not-allowed',
  MODERATOR_NOT_AUTHENTICATED: 'moderator-not-authenticated',
  MEMBER_DISCONNECTED: 'member-disconnected',
  REMOVED_FROM_CHANNEL: 'removed-from-channel',
  REMOVE_FROM_CHANNEL: 'remove-from-channel',
  INVITE_SHARING: 'invite-sharing',
  STOP_SHARING: 'stop-sharing',
  CLIENT_STREAM_CLOSED: 'client-stream-closed',
  CLIENT_STREAM_PAUSED: 'client-stream-paused',
  RESET_CHANNEL: 'reset-channel',
  CHANNEL_CLOSED: 'channel-closed',
  CONNECTION_REJECTED: 'connection-rejected',
  CHANGE_VOLUME: 'change-volume',
  VOLUME_MUTED: 'volume-muted',
  ERROR: 'error'
};

@Injectable({
  providedIn: 'root'
})
export class ScreenSharingService {
  socket: WebSocketSubject<SignalServerData>;
  socketSubscription: Subscription;
  peerConnection: RTCPeerConnection;
  channelError: string;
  joinCodeError: string;
  iceConfiguration: any;
  connecting: boolean = false;
  closing: boolean = false;
  mediaStream: MediaStream;
  joinedSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  invitedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  started = 0;
  name = '';
  moderator: boolean;
  memberId: string;
  senderId: string;
  invitedId: string;
  participants: ScreenShareMember[];
  channelTimeout: any;
  muted = false;

  get isModerated(): boolean {
    return this.participants?.find((item) => item.memberType === 'moderator') !== undefined;
  }

  get moderatorCount(): number {
    if (this.participants) {
      return this.participants.filter((item) => item.memberType === 'moderator').length;
    }
    return 0;
  }

  get sharing(): ScreenShareMember | null {
    const member = this.participants &&
      this.participants.find((user) => user.memberId === this.senderId);
    return member ? member : null;
  }

  get memberIsSharing(): boolean {
    return !!this.memberId && this.sharing?.memberId === this.memberId;
  }

  private readonly maxReconnectAttempts = 5; // Adjust this as needed
  private readonly reconnectInterval = 5000; // Adjust this as needed (milliseconds)
  private destroy$ = new Subject<void>();

  constructor(
    private userStateService: UserStateService,
    private webSocketService: WebSocketService,
    private rvshareAppDetectionService: RvshareAppDetectionService,
    private analyticsFactory: AnalyticsFactory
  ) { }

  private findParticipantIdFromMobileSender(senderId: string): string {
    if (!this.participants || this.participants.length === 0) {return '';}

    const participant = this.participants.find((member) => member.mobileMemberId === senderId);

    return participant ? participant.memberId : '';
  }

  private associateMobileIds(members?: ScreenShareMember[]): void {
    if (!members) {return;}

    members.forEach(member=>{
      if (member.memberType !== 'mobile') {return;}

      const participant = this.participants.find(participant=>participant.memberId === member.appsMemberId);

      if (!participant) {return;}

      participant.mobileMemberId = member.memberId;
    });
  }

  private filterParticipants(members?: ScreenShareMember[]): void {
    if (members) {
      this.participants = members
        .filter((member) => ['moderator', 'streamer'].includes(member.memberType))
        .sort((a) => a.memberType === 'moderator' ? -1 : 0);
    }

    this.associateMobileIds(members);
  }

  join(joinCode: string, name: string, moderator?: boolean): void {
    console.log('Joining channel with join code: ', joinCode);
    this.channelError = '';
    this.joinCodeError = '';
    this.name = name;
    this.connecting = true;
    this.closing = false;
    this.moderator = !!moderator;

    this.connectToWebSocket(joinCode, name)
      .pipe(
        takeUntil(this.destroy$),
        retryWhen(errors =>
          errors.pipe(
            delayWhen(() => {
              console.log(`Retrying WebSocket connection in ${this.reconnectInterval}ms...`);
              return timer(this.reconnectInterval);
            }),
            scan((retryCount, err) => {
              if (retryCount >= this.maxReconnectAttempts) {
                throw err; // Throw error to stop retrying
              } else {
                console.log(`WebSocket reconnecting attempt ${retryCount + 1}`);
                return retryCount + 1;
              }
            }, 0), // Initial retry count
            catchError(err => {
              console.error(`WebSocket retry attempts exhausted (${this.maxReconnectAttempts}).`);
              throw err; // Rethrow the error to propagate it to the subscription's error handler
            })
          )
        )
      )
      .subscribe({
        next: (data) => {
          console.log('streamer onmessage', data);

          switch (data.type) {
            case MessageType.JOINED_CHANNEL:
              console.log("Joined a new channel", data);
              this.channelError = '';
              this.joinCodeError = '';
              this.iceConfiguration = data.iceConfiguration;
              this.filterParticipants(data.members);

              this.memberId = data.memberId;

              this.joinedSubject.next(MessageType.JOINED_CHANNEL);

              if (this.rvshareAppDetectionService.isScreenShareApp()) {
                const withStart = this.isModerated ? "" : "AndStart";

                this.connecting = withStart ? true : false;

                const path = `join${withStart}?displayId=${data.displayId}&appsMemberId=${data.memberId}`;

                this.rvshareAppDetectionService.issueDeepLink(path);

                return;
              }

              this.connecting = false;

              if (!this.isModerated) {
                this.start();
              }

              break;

            case MessageType.INVALID_CHANNEL:
              console.warn('Invalid channel: ', data);
              this.joinCodeError = 'Invalid join code: ' + joinCode + '.';
              this.connecting = false;
              this.reset();
              break;

            case MessageType.ICECANDIDATE:
              if (data.memberId === this.memberId) {
                this.peerConnection?.addIceCandidate(data.candidate);
              }
              break;

            case MessageType.OFFER:
              this.senderId = this.findParticipantIdFromMobileSender(data.senderId) || data.senderId;
              break;

            case MessageType.ANSWER:
              console.log('Received answer');
              this.invitedId = '';
              this.channelError = '';
              this.connecting = false;
              clearTimeout(this.channelTimeout);
              if (this.senderId === this.memberId) {
                this.peerConnection?.setRemoteDescription({
                  type: data.type,
                  sdp: data.sdp ?? ""
                });
              }
              if (this.senderId === this.memberId || this.moderator) {
                this.joinedSubject.next(MessageType.ANSWER);
              }
              break;

            case MessageType.NEW_MEMBER:
              let streaming = !!this.peerConnection;
              console.log('New member joined', data, 'streaming', streaming);
              if (streaming) {
                console.log('Restarting stream');
                this.closePeerConnection();
                this.stream();
              }

              this.filterParticipants(data.members);
              break;

            case MessageType.MODERATOR_NOT_ALLOWED:
              this.channelError = 'This display is not configured for screen share moderation. Please check your display settings.';
              this.joinedSubject.next(MessageType.ERROR);
              break;

            case MessageType.MODERATOR_NOT_AUTHENTICATED:
              this.channelError = 'Authentication failed. Please log in and try again.';
              this.joinedSubject.next(MessageType.ERROR);
              break;

            case MessageType.MEMBER_DISCONNECTED:
              this.filterParticipants(data.members);
              break;

            case MessageType.REMOVED_FROM_CHANNEL:
              this.channelError = 'A moderator has removed you from the room.';
              this.joinedSubject.next(MessageType.ERROR);
              break;

            case MessageType.INVITE_SHARING:
              this.invitedSubject.next(true);
              break;

            case MessageType.STOP_SHARING:
              this.invitedSubject.next(false);
              break;

            case MessageType.CLIENT_STREAM_CLOSED:
              this.closeStream();
              if (data.senderId === this.invitedId) {
                this.invitedId = '';
              }
              break;
            case MessageType.CONNECTION_REJECTED:
              this.closing = true;
              this.connecting = false;
              this.channelError = 'The connection was rejected because the channel already has a streamer connected.';
              this.joinedSubject.next(MessageType.ERROR);
              break;
            case MessageType.VOLUME_MUTED:
              this.muted = true;
              break;
            default:
              console.log('Unknown message', data);
          }
        },
        error: (e) => {
          console.log('WebSocket error:', e);
          this.channelError = 'Connection could not be established. Please check your network connection.';
          this.joinedSubject.next(MessageType.ERROR);
          this.connecting = false;
          this.reset();
        }
      });
  }

  private closeStream() {
    this.senderId = '';
    this.joinedSubject.next(MessageType.CLIENT_STREAM_CLOSED);
  }

  private connectToWebSocket(joinCode: string, name: string): Observable<SignalServerData> {
    return new Observable<SignalServerData>(observer => {
      this.socket = this.webSocketService.webSocket({
        url: `${environment.SCREEN_SHARING_SERVICE_URL}?${
          this.moderator ?
            `memberType=moderator&displayId=${joinCode}&token=${'Bearer ' + this.userStateService.getAccessToken().access_token}`
          : `memberType=streamer&joinCode=${joinCode}`}&memberDisplayName=${encodeURIComponent(name)
        }`,
        closeObserver: {
          next: () => {
            // The user who initiated the channel reset does not neeed to see this message, and has already stopped streaming
            if (!this.closing) {
              if (this.mediaStream) {
                this.stop();
              }
              this.channelError = 'The session has ended.';
              this.joinedSubject.next(MessageType.ERROR);
            }
            this.closing = false;
          }
        }
      }

      );
      this.socketSubscription = this.socket.subscribe({
        next: (data) => observer.next(data),
        error: (err) => observer.error(err),
        complete: () => observer.complete()
      });
    });
  }

  start() {
    this.connecting = true;

    if (this.rvshareAppDetectionService.isScreenShareApp()) {
      this.rvshareAppDetectionService.issueDeepLink('start');
      return;
    }

    const mediaOptions = {
      video: {
        width: { ideal: 1920 },
        height: { ideal: 1080 }
      },
      audio: true
    };

    navigator.mediaDevices.getDisplayMedia(mediaOptions)
      .then((mediaStream: MediaStream) => {
        this.mediaStream = mediaStream;
        this.senderId = this.memberId;
        this.joinedSubject.next(MessageType.ANSWER);

        mediaStream.getTracks()
          .forEach(track => {
            track.addEventListener("ended", (e) => {
              console.log("track removed", e);

              this.socket.next({ type: MessageType.CLIENT_STREAM_CLOSED });

              this.closeStream();
              this.closePeerConnection();
            });
          });

        this.stream();
      })
      .catch(e => {
        console.log('getDisplayMedia error: ', e);
        this.joinedSubject.next(MessageType.JOINED_CHANNEL);
      })
      .finally(() => {
        this.connecting = false;
        const now = new Date();
        this.started = now.getTime();
        this.analyticsFactory.track('Screen Sharing Started', {
          timestamp: now.toISOString()
        });
      });
  }

  changeVolume(value: number) {
    this.socket.next({ type: MessageType.CHANGE_VOLUME, value });
  }

  interrupt() {
    this.channelError = 'The connection was interrupted, please try again.';
    this.closePeerConnection();
    if (this.isModerated) {
      this.resetChannel();
    } else {
      this.reset();
    }
  }

  stream() {
    if (this.rvshareAppDetectionService.isScreenShareApp()) {return;}

    this.peerConnection = new RTCPeerConnection(this.iceConfiguration);
    this.peerConnection.addEventListener(
      "connectionstatechange",
      () => {
        console.log('peerConnection.connectionstatechange', this.peerConnection.connectionState);

        switch (this.peerConnection.connectionState) {
          case "new":
          case "connecting":
          case "connected":
            break;
          case "disconnected":
          case "failed":
            this.channelError = 'The connection was interrupted, sharing will resume when the display reconnects.';
            clearTimeout(this.channelTimeout);
            this.channelTimeout = setTimeout(() => {
              this.interrupt();
            }, 180_000);
            break;
          case "closed":
            this.closePeerConnection();
            break;
          default:
            break;
        }
      },
      false,
    );

    this.peerConnection.addEventListener(MessageType.ICECANDIDATE, e => {
      let candidate = null;
      if (e.candidate !== null) {
        candidate = {
          candidate: e.candidate.candidate,
          sdpMid: e.candidate.sdpMid,
          sdpMLineIndex: e.candidate.sdpMLineIndex,
        };
      }
      this.socket.next({ type: MessageType.ICECANDIDATE, candidate });
    });

    this.mediaStream.getTracks()
      .forEach(track => this.peerConnection.addTrack(track, this.mediaStream));

    this.peerConnection.createOffer({ offerToReceiveAudio: true, offerToReceiveVideo: true })
      .then(async offer => {
        await this.peerConnection.setLocalDescription(offer);
        console.log('Created offer, sending...');
        this.socket.next({ type: MessageType.OFFER, sdp: offer.sdp });
      });
  }

  remove(memberId: string) {
    if (this.senderId === memberId) {
      this.stopSharing();
    }
    this.socket.next({ type: MessageType.REMOVE_FROM_CHANNEL, memberId });
  }

  invite(memberId: string) {
    this.invitedId = memberId;
    this.socket.next({ type: MessageType.INVITE_SHARING, memberId });
  }

  stopSharing() {
    this.socket.next({ type: MessageType.STOP_SHARING, memberId: this.senderId });
  }

  pause() {
    this.closePeerConnection();
    this.socket?.next({ type: MessageType.CLIENT_STREAM_PAUSED });
  }

  stop() {
    this.senderId = '';

    if (this.mediaStream) {
      this.mediaStream.getTracks().forEach(track => track.stop());
      this.mediaStream = null;
    }

    this.closePeerConnection();

    if (this.rvshareAppDetectionService.isScreenShareApp()) {
      this.rvshareAppDetectionService.issueDeepLink('stop');
    }

    this.socket?.next({ type: MessageType.CLIENT_STREAM_CLOSED });
  }

  leave() {
    if (this.mediaStream) {
      this.stop();
    }
    this.closing = true;
    this.socketSubscription?.unsubscribe();
    this.socket.complete();
    this.participants = [];
    this.joinedSubject.next(MessageType.CHANNEL_CLOSED);

    if (this.rvshareAppDetectionService.isScreenShareApp()) {
      this.rvshareAppDetectionService.issueDeepLink('leave');
    }
  }

  reset() {
    this.closing = true;
    this.muted = false;
    this.destroy$.next();
    this.destroy$.complete();
    this.leave();
  }

  resetChannel() {
    this.socket.next({ type: MessageType.RESET_CHANNEL });
    this.reset();
  }

  closePeerConnection() {
    if (this.peerConnection) {
      try {
        this.peerConnection.close();
      } catch (e) {
        console.error('Error closing peerConnection', e);
      } finally {
        this.peerConnection = null;
        const now = new Date();
        this.analyticsFactory.track('Screen Sharing Ended', {
          timestamp: now.toISOString(),
          duration: Math.round((now.getTime() - this.started) / 1000)
        });
      }
    }
  }
}
